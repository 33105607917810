import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import WebMaintenance from './pages/web_maintenance';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path = "/" element = {<HomePage />}/> */}
        <Route exact path='/*' element = {<WebMaintenance />} />
      </Routes>
    </Router>
  );
}

export default App;
